import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin.component';
import { ViewProductsComponent } from './view-products/view-products.component';
import { EditProductsComponent } from './edit-products/edit-products.component';
import { AddProductComponent } from './add-product/add-product.component';

const routes: Routes = [
  {
    path:'admin', component:AdminComponent,
    children:[
      {path:'view-products', component:ViewProductsComponent},
      {path:'edit-products', component:EditProductsComponent},
      {path:'add-product',component:AddProductComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
