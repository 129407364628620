<section>
  <span class="sub-header">Product List</span>
  <br/>
  <div class="flex gap-4">
      <div class="relative">
          <span class="absolute inset-y-0 left-0 flex items-center pl-3">
              <i class="pi pi-search text-dark-gray"></i>
          </span>
          <input type="text" [(ngModel)]="searchValue" (ngModelChange)="filterBySearch()" class="pl-10 pr-4 py-2 border border-light-gray rounded-md focus:outline-none focus:border-blue-500 search-field" placeholder="Search a product">
      </div>
      <div class="w-1/2">
        <div class="flex gap-4 items-center multi-select">
          <p-multiSelect [options]="categories" [(ngModel)]="selectedCategories" (onChange)="onCategoriesChange($event.value)" optionLabel="label" placeholder="Filter by category"></p-multiSelect>
          <p-multiSelect [options]="companies" [(ngModel)]="selectedCompanies" (onChange)="onCompaniesChange($event.value)" optionLabel="label" placeholder="Filter by company"></p-multiSelect>
      </div>  
      </div>
  </div>
</section>
<div class="card">
  <p-table
    styleClass="p-datatable-sm"
    [value]="tempProducts"
    dataKey="id"
    editMode="row"
    [paginator]="true"
    [rows]="10"
    class=" border-gray-300"
    [tableStyle]="{ 'min-width': '50rem' }"
  >
    <ng-template pTemplate="header">
      <tr class="border-b border-gray-300">
        <th style="width: 5%">SI.No.</th>
        <th style="width: 20%">Category</th>
        <th style="width: 20%">Company</th>
        <th style="width: 20%">Product Name</th>
        <th style="width: 5%">Features</th>
        <th style="width: 5%">Tables</th>
        <th style="width: 5%">Cheatsheet</th>
        <th style="width: 9%">Sales Pointers</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product let-editing="editing" let-ri="rowIndex">
      <tr [pEditableRow]="product" class="border-b border-gray-300">
        <td class="p-2">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="product.id" />
            </ng-template>
            <ng-template pTemplate="output">
              {{ product.id }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="p-2">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-dropdown
                [options]="categories"
                appendTo="body"
                [(ngModel)]="product.category"
                [style]="{ 'width': '100%' }"
              ></p-dropdown>
            </ng-template>
            <ng-template pTemplate="output">
              {{product.category}} 
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="p-2">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-dropdown
                [options]="companies"
                appendTo="body"
                [(ngModel)]="product.company"
                [style]="{ 'width': '100%' }"
              ></p-dropdown>
            </ng-template>
            <ng-template pTemplate="output">
              {{product.company}} 
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="p-2">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="product.productName" />
            </ng-template>
            <ng-template pTemplate="output">
              {{ product.productName }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="p-2">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <i *ngIf="product.features!=null" class="pi pi-file text-dark-gray hover:cursor-pointer"></i>
            </ng-template>
            <ng-template pTemplate="output">
              <i *ngIf="product.features!=null" (click)="previewFile(product, product.features)" class="pi pi-file text-dark-gray hover:cursor-pointer"></i>
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="p-2">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <i *ngIf="product.tables!=null" class="pi pi-file text-dark-gray"></i>
            </ng-template>
            <ng-template pTemplate="output">
              <i *ngIf="product.tables!=null" (click)="previewFile(product, product.tables)" class="pi pi-file text-dark-gray hover:cursor-pointer"></i>
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="p-2">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <i *ngIf="product.cheatsheet!=null" class="pi pi-file text-dark-gray"></i>
            </ng-template>
            <ng-template pTemplate="output">
              <i *ngIf="product.cheatsheet!=null" (click)="previewFile(product, product.cheatsheet)" class="pi pi-file text-dark-gray hover:cursor-pointer"></i>
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="p-2">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <i *ngIf="product.salesPointers!=null" (click)="previewFile(product, product.salesPointers)" class="pi pi-file text-dark-gray hover:cursor-pointer"></i>
            </ng-template>
            <ng-template pTemplate="output">
              <i *ngIf="product.salesPointers!=null" (click)="previewFile(product, product.salesPointers)" class="pi pi-file text-dark-gray hover:cursor-pointer"></i>
            </ng-template>
          </p-cellEditor>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-dialog header="Document Preview" [(visible)]="displayDialog" [modal]="true" [style]="{width: '60vw'}">
    <iframe *ngIf="fileUrl" [src]="fileUrl" width="100%" height="600px"></iframe>
  </p-dialog>
</div>
