<div class="relative p-4">
    <form [formGroup]="productForm" class="w-3/5 space-y-6">
        <div class="flex items-center space-x-4">
            <label for="category" class="block text-sm font-medium text-gray-700 w-1/3">Category</label>
            <div class="w-full">
                <p-dropdown id="category" formControlName="category" [options]="categories"
                    placeholder="Select a category" class="w-full"></p-dropdown>
                <!-- <div *ngIf="productForm.get('category')?.invalid && productForm.get('category')?.touched" class="text-red-500 text-sm">Category is required</div> -->
            </div>
        </div>
        <hr>

        <div class="flex items-center space-x-4">
            <label for="company" class="block text-sm font-medium text-gray-700 w-1/3">Company</label>
            <div class="w-full">
                <p-dropdown id="company" formControlName="company" [options]="companies" placeholder="Select a company"
                    class="w-full"></p-dropdown>
                <!-- <div *ngIf="productForm.get('company')?.invalid && productForm.get('company')?.touched" class="text-red-500 text-sm">Company is required</div> -->
            </div>
        </div>
        <hr>

        <div class="flex items-center space-x-4">
            <label for="productName" class="block text-sm font-medium text-gray-700 w-1/3">Product Name</label>
            <div class="w-full">
                <input id="productName" type="text" formControlName="productName"
                    class="block w-full mt-1 px-3 py-2 border border-light-gray rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter product name">
                <!-- <div *ngIf="productForm.get('productName')?.invalid && productForm.get('productName')?.touched" class="text-red-500 text-sm">Product Name is required</div> -->
            </div>
        </div>
        <hr>

        <div class="flex file-upload-section">
            <label class="block text-sm font-medium text-gray-700 documents-label">Documents to upload</label>
            <div class="w-3/5">
                <div class="flex items-center space-x-4 mb-4">
                    <label for="productFeatures" class="block text-sm font-medium text-gray-700 w-1/3">Product
                        Features</label>
                    <div class="w-full">
                        <input id="productFeatures" formControlName="productFeatures" type="file" (change)="onFileChange($event, 'productFeatures')"
                            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500">
                        <div *ngIf="productForm.get('productFeatures')?.invalid && productForm.get('productFeatures')?.touched"
                            class="text-red text-sm">Product Features document is required</div>
                    </div>
                </div>

                <div class="flex items-center space-x-4 mb-4">
                    <label for="productTables" class="block text-sm font-medium text-gray-700 w-1/3">Product
                        Tables</label>
                    <div class="w-full">
                        <input id="productTables" formControlName="productTables" type="file" (change)="onFileChange($event, 'productTables')"
                            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500">
                    </div>
                </div>

                <div class="flex items-center space-x-4 mb-4">
                    <label for="cheatsheet" class="block text-sm font-medium text-gray-700 w-1/3">Cheatsheet</label>
                    <div class="w-full">
                        <input id="cheatsheet" formControlName="cheatsheet" type="file" (change)="onFileChange($event, 'cheatsheet')"
                            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500">
                    </div>
                </div>

                <div class="flex items-center space-x-4 mb-4">
                    <label for="salesPointers" class="block text-sm font-medium text-gray-700 w-1/3">Sales
                        Pointers</label>
                    <div class="w-full">
                        <input id="salesPointers" formControlName="salesPointers"  type="file" (change)="onFileChange($event, 'salesPointers')"
                            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500">
                        <div *ngIf="productForm.get('salesPointers')?.invalid && productForm.get('salesPointers')?.touched"
                            class="text-red text-sm">Sales Pointers document is required</div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="absolute bottom-2 right-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700">
        <button (click)="onSubmit()" type="button" class="px-8 py-3 font-semibold rounded bg-light-gray">Submit</button>
    </div>
    <div class="dialog">
        <p-dialog class="mx-auto" [modal]="true" [(visible)]="visible" [style]="{ width: '25rem' }">
            <div class="flex flex-col items-center">
                <div class="mb-4">
                    <i class="pi pi-check-circle text-success-green text-5xl"></i>
                </div>
                <span class="text-center mb-4">{{productName}} is added to the list</span>
                <button (click)="visible=false" type="button"
                    class="px-8 py-3 font-semibold rounded bg-light-gray">OK</button>
            </div>
        </p-dialog>
    </div>
</div>