import { Component } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { Product } from 'src/app/view-models/Products';

@Component({
  selector: 'app-edit-products',
  templateUrl: './edit-products.component.html',
  styleUrls: ['./edit-products.component.css']
})
export class EditProductsComponent {
  products!: Product[];
  categories!: SelectItem[];
  companies!: SelectItem[];
  clonedProducts: { [s: string]: Product } = {};
  selectedCategories: any[] = [];
  selectedCompanies: any[] = [];
  tempProducts: Product[];
  searchValue: string = '';
  visible: boolean = false;
  isEditable: boolean = true;
  showDeleteSuccessDialog: boolean = false;
  showFileChangeDialog: boolean = false;
  showEditsSuccessDialog: boolean = false;

  constructor(private apiService: ApiService) {

  }

  ngOnInit() {
    this.apiService.getProducts().then((data) => {
      this.products = data;
      this.tempProducts = data;
    });

    this.categories = [
      { label: 'Life', value: 'Life' },
      { label: 'Health', value: 'Health' }
    ];

    this.companies =[
      {label: "LIC", value: "LIC"},
      {label: "Max Life", value: "Max Life"},
      {label: "HDFC Life", value: "HDFC Life"},
      {label: "ICICI Prudential", value: "ICICI Prudential"},
      {label: "Kotak Mahindra Life", value: "Kotak Mahindra Life"},
      {label: "Aditya Birla Sun Life", value: "Aditya Birla Sun Life"},
      {label: "Tata AIA", value: "Tata AIA"},
      {label: "SBI Life", value: "SBI Life"},
      {label: "Bajaj Allianz Life", value: "Bajaj Allianz Life"},
      {label: "PNB MetLife", value: "PNB MetLife"},
      {label: "Reliance Nippon Life", value: "Reliance Nippon Life"},
      {label: "Aviva Life", value: "Aviva Life"},
      {label: "Sahara India Life", value: "Sahara India Life"},
      {label: "Shriram Life", value: "Shriram Life"},
      {label: "Bharti AXA Life", value: "Bharti AXA Life"},
      {label: "Future Generali Life", value: "Future Generali Life"},
      {label: "Aegas Federal Life", value: "Aegas Federal Life"},
      {label: "Canara HSBC Life", value: "Canara HSBC Life"},
      {label: "Bandhan Life", value: "Bandhan Life"},
      {label: "Pramerica Life", value: "Pramerica Life"},
      {label: "Star Union Dai-Ichi Life", value: "Star Union Dai-Ichi Life"},
      {label: "IndiaFirst Life", value: "IndiaFirst Life"},
      {label: "Edelweiss Tokio Life", value: "Edelweiss Tokio Life"},
      {label: "Credit Access Life", value: "Credit Access Life"},
      {label: "Acko Life", value: "Acko Life"},
      {label: "Go Digit Life", value: "Go Digit Life"},
      {label: "Acko General", value: "Acko General"},
      {label: "Bajaj Allianz General", value: "Bajaj Allianz General"},
      {label: "Cholamandalam MS", value: "Cholamandalam MS"},
      {label: "Future Generali India", value: "Future Generali India"},
      {label: "Go Digit General", value: "Go Digit General"},
      {label: "HDFC Ergo", value: "HDFC Ergo"},
      {label: "ICICI Lombard", value: "ICICI Lombard"},
      {label: "IFFCO Tokio", value: "IFFCO Tokio"},
      {label: "Kotak Mahindra General", value: "Kotak Mahindra General"},
      {label: "Kshema General", value: "Kshema General"},
      {label: "Liberty General", value: "Liberty General"},
      {label: "Magma HDI", value: "Magma HDI"},
      {label: "National Insurance Company", value: "National Insurance Company"},
      {label: "Navi General", value: "Navi General"},
      {label: "Raheja QBE General", value: "Raheja QBE General"},
      {label: "Reliance General", value: "Reliance General"},
      {label: "Royal Sundaram", value: "Royal Sundaram"},
      {label: "SBI General", value: "SBI General"},
      {label: "Shriram General", value: "Shriram General"},
      {label: "Tata AIG", value: "Tata AIG"},
      {label: "The New India Assurance", value: "The New India Assurance"},
      {label: "The Oriental Insurance", value: "The Oriental Insurance"},
      {label: "United India Insurance", value: "United India Insurance"},
      {label: "Universal Sompe General", value: "Universal Sompe General"},
      {label: "Zuno General", value: "Zuno General"},
      {label: "Aditya Birla Health", value: "Aditya Birla Health"},
      {label: "Care Health", value: "Care Health"},
      {label: "Galaxy Health", value: "Galaxy Health"},
      {label: "Narayana Health", value: "Narayana Health"},
      {label: "Manipal Cigna", value: "Manipal Cigna"},
      {label: "Niva Bupa", value: "Niva Bupa"},
      {label: "Star Health", value: "Star Health"}
  ];
  }

  onRowEditInit(product: Product) {
    this.isEditable = true;
    this.clonedProducts[product.id as string] = { ...product };
  }

  onRowEditSave(product: Product) {
     this.showEditsSuccessDialog = true;
  }

  onRowEditCancel(product: Product, index: number) {
    this.products[index] = this.clonedProducts[product.id as string];
    delete this.clonedProducts[product.id as string];
  }

  filterBySearch(){
    this.tempProducts = this.products.filter(p=>p.productName.toLowerCase().includes(this.searchValue.toLowerCase()));  
  }

  selectedCities: string[] = [];
  cities: { name: string, code: string }[];

  filter(){
    let temp = [];
    let filteredData = [];
    if (this.selectedCategories.length === 0 && this.selectedCompanies.length === 0) {
      this.tempProducts = this.products;
    } else {
      this.selectedCategories.forEach(element => {
        temp = this.products.filter(p=>p.category === element.value)
      });
     filteredData=[... temp]
     temp = [];
     this.selectedCompanies.forEach(element => {
      temp = this.products.filter(p=>p.company === element.value)
    });
    filteredData.push(temp);
    }
  }

  onFileChange(event: any, field: string) {
    this.showFileChangeDialog = true;
    this.visible = false;
    const file = event.target.files[0];
    //this.productForm?.get(field)?.setValue(file);
  }
}
