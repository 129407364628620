import { Injectable } from '@angular/core';
import { API } from '../view-models/Constants';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  
  public BaseURL = API.prodBaseURL;
  public apiKey = "msnd19829ASDn8786SS"

  constructor(public http: HttpClient) { }

  httpGetHelp(apiURL: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.apiKey}`
    });
    return this.http.get<any>(this.BaseURL+apiURL, { headers });
  }

  httpGetFileHelp(apiURL: string): Observable<HttpResponse<Blob>> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.apiKey}`
    });
    return this.http.get(this.BaseURL+apiURL, { observe:'response', responseType: 'blob', headers  });
  }

  httpPostHelp(apiURL: string, data: any): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.apiKey}`
    });
    return this.http.post<any>(this.BaseURL+apiURL, data,{ headers });
  }

  getProducts() {
    return Promise.resolve(this.getProductsData());
  } 

  getProductsData() {
    return [
      {
        id: '1000',
        category: 'Life',
        company: 'HDFC Life',
        productName: 'Click2Protect Super',
        features: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        tables: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        cheatsheet: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        salesPointers: 'https://www.clickdimensions.com/links/TestPDFfile.pdf'
      },
      {
        id: '1001',
        category: 'Life',
        company: 'Star Health',
        productName: 'Click2Protect Super',
        features: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        tables: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        cheatsheet: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        salesPointers: 'https://www.clickdimensions.com/links/TestPDFfile.pdf'
      },
      {
        id: '1002',
        category: 'Life',
        company: 'Star Health',
        productName: 'Click2Protect Super',
        features: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        tables: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        cheatsheet: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        salesPointers: 'https://www.clickdimensions.com/links/TestPDFfile.pdf'
      },
      {
        id: '1003',
        category: 'Health',
        company: 'HDFC Life',
        productName: 'Click2Protect Super',
        features: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        tables: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        cheatsheet: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        salesPointers: 'https://www.clickdimensions.com/links/TestPDFfile.pdf'
      },
      {
        id: '1004',
        category: 'Life',
        company: 'Star Health',
        productName: 'Click2Protect Super',
        features: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        tables: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        cheatsheet: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        salesPointers: 'https://www.clickdimensions.com/links/TestPDFfile.pdf'
      },
      {
        id: '1005',
        category: 'Life',
        company: 'Star Health',
        productName: 'Click2Protect Super',
        features: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        tables: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        cheatsheet: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        salesPointers: 'https://www.clickdimensions.com/links/TestPDFfile.pdf'
      },
      {
        id: '1006',
        category: 'Life',
        company: 'HDFC Life',
        productName: 'Click2Protect Super',
        features: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        tables: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        cheatsheet: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        salesPointers: 'https://www.clickdimensions.com/links/TestPDFfile.pdf'
      },
      {
        id: '1007',
        category: 'Life',
        company: 'HDFC Life',
        productName: 'Click2Protect Super',
        features: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        tables: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        cheatsheet: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        salesPointers: 'https://www.clickdimensions.com/links/TestPDFfile.pdf'
      },
      {
        id: '1008',
        category: 'Life',
        company: 'HDFC Life',
        productName: 'Click2Protect Super',
        features: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        tables: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        cheatsheet: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        salesPointers: 'https://www.clickdimensions.com/links/TestPDFfile.pdf'
      },
      {
        id: '1009',
        category: 'Life',
        company: 'HDFC Life',
        productName: 'Click2Protect Super',
        features: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        tables: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        cheatsheet: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        salesPointers: 'https://www.clickdimensions.com/links/TestPDFfile.pdf'
      },
      {
        id: '1010',
        category: 'Life',
        company: 'HDFC Life',
        productName: 'Click2Protect Super',
        features: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        tables: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        cheatsheet: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        salesPointers: 'https://www.clickdimensions.com/links/TestPDFfile.pdf'
      },
      {
        id: '1011',
        category: 'Life',
        company: 'HDFC Life',
        productName: 'Click2Protect Super',
        features: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        tables: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        cheatsheet: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        salesPointers: 'https://www.clickdimensions.com/links/TestPDFfile.pdf'
      },
      {
        id: '1012',
        category: 'Life',
        company: 'HDFC Life',
        productName: 'Click2Protect Super',
        features: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        tables: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        cheatsheet: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        salesPointers: 'https://www.clickdimensions.com/links/TestPDFfile.pdf'
      },
      {
        id: '1013',
        category: 'Life',
        company: 'HDFC Life',
        productName: 'Click2Protect Super',
        features: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        tables: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        cheatsheet: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        salesPointers: 'https://www.clickdimensions.com/links/TestPDFfile.pdf'
      },
      {
        id: '1014',
        category: 'Life',
        company: 'HDFC Life',
        productName: 'Click2Protect Super',
        features: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        tables: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        cheatsheet: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        salesPointers: 'https://www.clickdimensions.com/links/TestPDFfile.pdf'
      },
      {
        id: '1015',
        category: 'Life',
        company: 'HDFC Life',
        productName: 'Click2Protect Super',
        features: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        tables: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        cheatsheet: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        salesPointers: 'https://www.clickdimensions.com/links/TestPDFfile.pdf'
      },
      {
        id: '1016',
        category: 'Life',
        company: 'HDFC Life',
        productName: 'Click2Protect Super',
        features: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        tables: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        cheatsheet: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        salesPointers: 'https://www.clickdimensions.com/links/TestPDFfile.pdf'
      },
      {
        id: '1017',
        category: 'Life',
        company: 'HDFC Life',
        productName: 'Click2Protect Super',
        features: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        tables: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        cheatsheet: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        salesPointers: 'https://www.clickdimensions.com/links/TestPDFfile.pdf'
      },
      {
        id: '1018',
        category: 'Life',
        company: 'HDFC Life',
        productName: 'Click2Protect Super',
        features: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        tables: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        cheatsheet: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        salesPointers: 'https://www.clickdimensions.com/links/TestPDFfile.pdf'
      },
      {
        id: '1019',
        category: 'Life',
        company: 'HDFC Life',
        productName: 'Click2Protect Super',
        features: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        tables: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        cheatsheet: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        salesPointers: 'https://www.clickdimensions.com/links/TestPDFfile.pdf'
      },
      {
        id: '1020',
        category: 'Life',
        company: 'HDFC Life',
        productName: 'Click2Protect Super',
        features: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        tables: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        cheatsheet: 'https://www.clickdimensions.com/links/TestPDFfile.pdf',
        salesPointers: 'https://www.clickdimensions.com/links/TestPDFfile.pdf'
      }
    ];
  }

  getLifeProducts(category: string){ // Gets the grid data

    let api = "/products/"+category+"/";
    return this.httpGetHelp(api);
  }

  getFilesForProduct(product: string, category: string){
    let api = "/products/"+category+"/"+product;
    return this.httpGetHelp(api);
  }

  addProduct(category: string, company: string, product:string, formData: FormData){
    let api = "/upload/"+category+"/"+company+"/"+product;
    return this.httpPostHelp(api, formData);
  }

  getFile(category: string, company: string, product:string, fileName: string){
    let api = "/products/"+category+"/"+company+"/"+product+"/"+fileName;
    return this.httpGetFileHelp(api);
  }
}
