import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent {
  currentMenu: string = 'View'

  constructor(private router: Router){
    router.navigate(['admin/view-products']);
  }

  setCurrentMenu(menu: string){
    this.currentMenu = menu;
    switch(menu){
      case 'View':
        this.router.navigate(['/admin/view-products']);
        break;
      case 'add':
        this.router.navigate(['/admin/add-product']);
        break;
      case 'edit':
        this.router.navigate(['/admin/edit-products']);
        break;
    }
  }
}
