import { Component, Inject } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-error-dialog',
  template: `
    <p>An error occurred!!!</p>
  `
})
export class ErrorDialogComponent {
  constructor( private ref: DynamicDialogRef) { }

  close() {
    this.ref.close();
  }
}